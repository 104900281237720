import { IsNotEmpty, Length } from 'class-validator';

import { IfDefineThanLength } from '@/utils/custom-validators';
import { Entity } from './Entity';
import { VALID_ERRORS } from '@/constants/errors';

interface LotInterface {
  id?: number;
  name?: string;
  description?: string;
  cost?: number;
  excludeFromExport?: boolean;
}

export class Lot extends Entity<Lot> implements LotInterface {
  id?: number;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 50, { message: VALID_ERRORS.length(3, 50) })
  name?: string;
  @IfDefineThanLength(0, 250, { message: VALID_ERRORS.length(0, 250) })
  description?: string;
  cost?: number;
  excludeFromExport?: boolean;

  constructor(data: LotInterface) {
    super();
    data.name && (this.name = data.name);
    data.description && (this.description = data.description);
    data.id && (this.id = data.id);
    data.cost && (this.cost = data.cost);
    data.excludeFromExport && (this.excludeFromExport = data.excludeFromExport);
  }

  replaceBy(data: LotInterface) {
    this.name = data.name || undefined;
    this.description = data.description || undefined;
    this.id = data.id || undefined;
    this.cost = data.cost || undefined;
    this.excludeFromExport = data.excludeFromExport || false;
  }
}
