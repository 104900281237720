import { createModule } from 'vuexok';

import store from '@/store';
import { PhotoCategory } from '@/interfaces/Photo';
import { Condition } from '@/classes/models/Part';
export const localsModule = createModule(store, 'locals', {
  state: {
    galleriaSelectedCategory: 0,
    parts: {
      donorId: 0,
      locationId: 0,
      lotId: 0,
      condition: Condition.CONTRACT,
      donorMode: 0,
      donorLock: true,
      marka: '',
      model: '',
      bodyNumber: '',
      engine: '',
      year: 1999,
    },
  },
  getters: {},
  mutations: {
    setGalleriaSelectedCategory(state, payload: PhotoCategory) {
      state.galleriaSelectedCategory = payload;
      localStorage.setItem('galleriaSelectedCategory', payload.toString());
    },

    setPartsPrefs(
      state,
      payload: {
        donorId?: number;
        condition?: Condition;
        locationId?: number;
        lotId?: number;
        donorMode?: number;
        donorLock?: boolean;
        marka?: string;
        model?: string;
        bodyNumber?: string;
        engine?: string;
        year?: number;
      }
    ) {
      payload.donorId && (state.parts.donorId = payload.donorId);
      payload.locationId && (state.parts.locationId = payload.locationId);
      payload.lotId && (state.parts.lotId = payload.lotId);
      payload.condition && (state.parts.condition = payload.condition);
      payload.donorMode && (state.parts.donorMode = payload.donorMode);
      payload.donorLock && (state.parts.donorLock = payload.donorLock);
      payload.marka && (state.parts.marka = payload.marka);
      payload.model && (state.parts.model = payload.model);
      payload.bodyNumber && (state.parts.bodyNumber = payload.bodyNumber);
      payload.engine && (state.parts.engine = payload.engine);
      payload.year && (state.parts.year = payload.year);

      localStorage.setItem('localPartsPrefs', JSON.stringify(state.parts));
    },
  },
  actions: {
    loadLocalNomenclatures() {
      const galleriaSelectedCategory = parseInt(
        localStorage.getItem('galleriaSelectedCategory') || '0'
      );
      const partsPrefsJson = localStorage.getItem('localPartsPrefs');
      if (partsPrefsJson) {
        const partsPrefs = JSON.parse(partsPrefsJson);
        localsModule.mutations.setPartsPrefs(partsPrefs);
      }

      localsModule.mutations.setGalleriaSelectedCategory(
        galleriaSelectedCategory
      );
    },
  },
});
