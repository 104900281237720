import { IsNotEmpty } from 'class-validator';

import { IfDefineThanLength } from '@/utils/custom-validators';
import { VALID_ERRORS } from '@/constants/errors';
import { Entity } from './Entity';
import { Photo } from '@/classes/Photo';

export enum Condition {
  NEW = 'New',
  CONTRACT = 'Contract',
  USED = 'Used',
}

export enum Pitch {
  LEFT = 'Left',
  RIGHT = 'Right',
}

export enum Yaw {
  TOP = 'Top',
  BOTTOM = 'Bottom',
}

export enum Roll {
  FRONT = 'Front',
  BACK = 'Back',
}

export interface Parts {
  data: Part[];
  page: number;
  pages: number;
  rows: number;
}

export interface PartInterface {
  donorId?: number;
  donor?: { id: number; name: string };
  nomenclatureId?: number;
  nomenclature?: { id: number; name: string };
  description?: string;
  comment?: string;
  id?: number;
  article?: number;
  name?: string;
  condition?: Condition;
  leftRight?: Yaw;
  topBottom?: Pitch;
  frontBack?: Roll;
  marka?: string;
  model?: string;
  bodyNumber?: string;
  engine?: string;
  year?: number;
  manufacturer?: string;
  oemNumber?: string;
  number?: string;
  color?: string;
  price?: number;
  discount?: number;
  images?: Photo[];
  lastInboundLot?: { id: number; name: string };
}

export class Part extends Entity<Part> implements PartInterface {
  donorId?: number;
  donor?: { id: number; name: string };
  @IsNotEmpty({ message: VALID_ERRORS.required })
  nomenclatureId?: number;
  nomenclature?: { id: number; name: string };
  @IfDefineThanLength(0, 250, { message: VALID_ERRORS.maxLength(250) })
  description?: string;
  @IfDefineThanLength(0, 250, { message: VALID_ERRORS.maxLength(250) })
  comment?: string;
  id?: number;
  article?: number;
  @IfDefineThanLength(0, 100, { message: VALID_ERRORS.maxLength(100) })
  name?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  condition?: Condition;
  leftRight?: Yaw;
  topBottom?: Pitch;
  frontBack?: Roll;
  @IfDefineThanLength(0, 50, { message: VALID_ERRORS.maxLength(50) })
  marka?: string;
  @IfDefineThanLength(0, 50, { message: VALID_ERRORS.maxLength(50) })
  model?: string;
  @IfDefineThanLength(0, 50, { message: VALID_ERRORS.maxLength(50) })
  bodyNumber?: string;
  @IfDefineThanLength(0, 50, { message: VALID_ERRORS.maxLength(50) })
  engine?: string;
  year?: number;
  @IfDefineThanLength(0, 30, { message: VALID_ERRORS.maxLength(30) })
  manufacturer?: string;
  @IfDefineThanLength(0, 30, { message: VALID_ERRORS.maxLength(30) })
  oemNumber?: string;
  @IfDefineThanLength(0, 30, { message: VALID_ERRORS.maxLength(30) })
  number?: string;
  @IfDefineThanLength(0, 30, { message: VALID_ERRORS.maxLength(30) })
  color?: string;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  price?: number;
  discount?: number;
  images?: Photo[];
  lastInboundLot?: { id: number; name: string };

  constructor(data: PartInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: PartInterface) {
    this.donorId = (data.donor && data.donor.id) || data.donorId || undefined;
    this.donor = data.donor || undefined;
    this.nomenclatureId =
      (data.nomenclature && data.nomenclature.id) ||
      data.nomenclatureId ||
      undefined;
    this.nomenclature = data.nomenclature || undefined;
    this.description = data.description || undefined;
    this.comment = data.comment || undefined;
    this.id = data.id || undefined;
    this.article = data.article || undefined;
    this.name = data.name || undefined;
    this.condition = data.condition || undefined;
    this.marka = data.marka || undefined;
    this.model = data.model || undefined;
    this.bodyNumber = data.bodyNumber || undefined;
    this.engine = data.engine || undefined;
    this.year = data.year || undefined;
    this.manufacturer = data.manufacturer || undefined;
    this.oemNumber = data.oemNumber || undefined;
    this.number = data.number || undefined;
    this.color = data.color || undefined;
    this.price = data.price || undefined;
    this.discount = data.discount || undefined;
    this.images = data.images || undefined;
    this.leftRight = data.leftRight || undefined;
    this.topBottom = data.topBottom || undefined;
    this.frontBack = data.frontBack || undefined;
    this.lastInboundLot = data.lastInboundLot || undefined;
  }
}
