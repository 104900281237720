import {RootEnitiy} from "@/interfaces/Enitiy";

export class Entity<T> implements RootEnitiy {
  static createFromObject<T>(c: { new (data: T): T }, data: T): T {
    return new c({ ...data });
  }

  static createFromArray<T>(c: { new (data: T): T }, data: T[]): T[] {
    return data.reduce((accum, item) => {
      accum.push(new c(item));
      return accum;
    }, [] as T[]);
  }
}
