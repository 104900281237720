import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';

import { IncomeLine } from '@/classes/models/Income';

export function IsEqualTo(
  property: string,
  validationOptions?: ValidationOptions
) {
  /* eslint-disable */
  return (object: any, propertyName: string) => {
    registerDecorator({
      name: 'isEqualTo',
      target: object.constructor,
      propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        /* eslint-disable */
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          /* eslint-disable */
          const relatedValue = (args.object as any)[relatedPropertyName];
          return value === relatedValue;
        },

        defaultMessage(args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          return `$property must match ${relatedPropertyName} exactly`;
        },
      },
    });
  };
}

export function IfDefineThanLength(
  min: number,
  max: number,
  validationOptions?: ValidationOptions
) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IfDefineThanLength',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          if (value) {
            if (
              typeof value === 'string' &&
              value.length >= min &&
              value.length <= max
            ) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        },
      },
    });
  };
}

export function IncomeLinesNotEmpty(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'IfDefineThanLength',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: IncomeLine[], args: ValidationArguments) {
          if (value) {
            if (!value.some(line => !line.partId)) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        },
      },
    });
  };
}
