import axios from '@/axios-zaconte';

import { Api } from './Api';
import { Lot } from '../models/Lot';
import CustomError from '../models/CustomError';
import { LotInboundReport, LotReport } from '@/interfaces/LotReport';

export class LotApi extends Api {
  async fetchAll(): Promise<Lot[]> {
    let lots: Lot[] = [];
    try {
      const result = await this.fetchAllProtected<Lot[]>('lots');
      if (result && result.length > 0) {
        lots = Lot.createFromArray(Lot, result);
      }
      return [...lots];
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Lot> {
    try {
      const result = await this.fetchByIdProtected<Lot>('lots', id);
      if (result) {
        return Lot.createFromObject(Lot, result);
      }
      return new Lot({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Lot): Promise<Lot> {
    try {
      const result = await this.sendProtected<Lot>('lots', data);
      return Lot.createFromObject(Lot, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Lot): Promise<Lot> {
    try {
      const result = await this.updateProtected('lots', data);
      return Lot.createFromObject(Lot, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('lots', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async report(id: number): Promise<LotReport> {
    try {
      return (await axios.get(`lots/${id}/info`)).data as LotReport;
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async inboundReport(
    id: number,
    page: number,
    pageSize: number,
    type: string
  ): Promise<LotInboundReport> {
    try {
      return (
        await axios.get(`reports/lot/${type}`, {
          params: { LotId: id, Page: page, PageSize: pageSize },
        })
      ).data as LotInboundReport;
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
