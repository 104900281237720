import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
  },
  {
    path: '/mobile-policy',
    name: 'MobilePolicy',
    meta: {
      auth: false,
      layout: 'doc',
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/MobilePolicy.vue'),
  },
  {
    path: '/products',
    name: 'Products',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '../views/Products.vue'),
    children: [
      {
        path: ':id',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "products" */ '../components/Products/ProductItem.vue'
          ),
        meta: {
          layout: 'main',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/incomes',
    name: 'incomes-parent',
    component: () =>
      import(/* webpackChunkName: "incomes" */ '../views/Incomes.vue'),
    meta: {
      layout: 'main',
      auth: true,
    },
  },
  {
    path: '/donors',
    name: 'Donors',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "donors" */ '../views/Donors.vue'),
  },
  {
    path: '/dictionaries',
    name: 'Dictionaries',
    redirect: '/dictionaries/nomenclature',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "dictionaries" */ '../views/dictionaries/Dictionaries.vue'
      ),
    children: [
      {
        path: 'nomenclature',
        name: 'nomenclature',
        component: () =>
          import(
            /* webpackChunkName: "nomenclature" */ '@/views/dictionaries/Nomenclature.vue'
          ),
      },
      {
        path: 'lots',
        name: 'lots',
        component: () =>
          import(
            /* webpackChunkName: "lots" */ '@/views/dictionaries/Lots.vue'
          ),
      },
      {
        path: 'locations',
        name: 'locations',
        component: () =>
          import(
            /* webpackChunkName: "locations" */ '@/views/dictionaries/Locations.vue'
          ),
      },
      {
        path: 'products',
        name: 'products-dictionary',
        component: () =>
          import(
            /* webpackChunkName: "products-dictionary" */ '@/views/dictionaries/Products.vue'
          ),
        children: [
          {
            path: ':id',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "products" */ '../components/Products/ProductItem.vue'
              ),
            meta: {
              layout: 'main',
              auth: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/sales',
    name: 'Sales',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "sales" */ '../views/Sales.vue'),
  },
  {
    path: '/writeoff',
    name: 'WriteOff',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "writeoff" */ '../views/WriteOff.vue'),
  },
  {
    path: '/galleria',
    name: 'Galleria',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "galleria" */ '../views/Galleria.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    redirect: '/services/profile',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "services" */ '../views/services/Services.vue'
      ),
    children: [
      {
        path: 'export',
        name: 'export',
        component: () =>
          import(
            /* webpackChunkName: "export" */ '@/views/services/Export.vue'
          ),
      },
      {
        path: 'import',
        name: 'import',
        component: () =>
          import(
            /* webpackChunkName: "import" */ '@/views/services/Import.vue'
          ),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: "profile" */ '@/views/services/Profile.vue'
          ),
      },
    ],
  },
  {
    path: '/reports',
    name: 'Reports',
    meta: {
      layout: 'main',
      auth: true,
    },
    component: () =>
      import(/* webpackChunkName: "reports" */ '../views/reports/Reports.vue'),
    children: [
      {
        path: 'lots',
        name: 'LotsReport',
        component: () =>
          import(
            /* webpackChunkName: "reports-lot" */ '@/views/reports/LotsReport.vue'
          ),
      },
    ],
  },
  {
    path: '/export/:id',
    props: { default: true },
    beforeEnter: to => {
      window.location.href = `${process.env.VUE_APP_REST_BASE_URL}export/${to.params.id}`;
    },
    name: 'ExportRedirect',
    meta: {
      layout: 'empty',
      auth: false,
    },
    components: {},
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ '../views/Login.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/Reset.vue'),
  },
  {
    path: '/doreset',
    name: 'DoReset',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "reset" */ '../views/DoReset.vue'),
  },
  {
    path: '/doverify',
    name: 'DoVerify',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "verify" */ '../views/DoVerify.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    meta: {
      layout: 'empty',
      auth: false,
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let isLoggedin = true;

  const token = localStorage.getItem('token');
  if (!token) {
    isLoggedin = false;
  }

  const requireAuth = to.matched.some(record => record.meta.auth);

  if (requireAuth && !isLoggedin) {
    next('/login');
  } else {
    next();
  }
});

export default router;
