import { AxiosRequestConfig } from 'axios';
import axios from '@/axios-zaconte';

export class Api {
  protected async fetchAllProtected<T>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.get<T>(path, config);
    if (result.status === 200 && result.data) {
      return result.data;
    } else {
      return result.data;
    }
  }

  protected async fetchFilteredProtected<T>(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.get<T>(path, config);
    if (result.status === 200 && result.data) {
      return result.data;
    } else {
      return result.data;
    }
  }

  protected async fetchByIdProtected<T>(
    path: string,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    const result = await axios.get<T>(`${path}/${id}`, config);
    if (result.status === 200 && result.data) {
      return result.data;
    } else {
      return null;
    }
  }

  protected async sendProtected<T>(
    path: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.post(path, data, config);
    return result.data;
  }

  protected async updateProtected<T>(
    path: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.patch(path, data, config);
    return result.data;
  }

  protected async updateManyProtected<T>(
    path: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.patch(path, data, config);
    return result.data;
  }

  protected async removeProtected<T>(
    path: string,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T> {
    const result = await axios.delete(`${path}/${id}`, config);
    return result.data;
  }

  protected async postProtected<T>(
    path: string,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    const result = await axios.get<T>(`${path}/${id}/post`, config);
    if (result.status === 200 && result.data) {
      return result.data;
    } else {
      return null;
    }
  }

  protected async unPostProtected<T>(
    path: string,
    id: number,
    config?: AxiosRequestConfig
  ): Promise<T | null> {
    const result = await axios.get<T>(`${path}/${id}/unpost`, config);
    if (result.status === 200 && result.data) {
      return result.data;
    } else {
      return null;
    }
  }
}
