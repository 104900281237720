import { createModule } from 'vuexok';
import store from '@/store';
import { MenuItem } from '@/interfaces/MenuItem';

const state: { menuItems: MenuItem[] } = {
  menuItems: [
    { id: 0, label: 'Товары', to: '/products', icon: 'pi pi-list' },
    {
      id: 1,
      label: 'Документы',
      icon: 'pi pi-file',
      items: [
        { id: 0, label: 'Продажи', to: '/sales' },
        { id: 1, label: 'Поступления', to: '/incomes' },
        { id: 2, label: 'Списание', to: '/writeoff' },
      ],
    },

    {
      id: 2,
      label: 'Справочники',
      icon: 'pi pi-book',
      items: [
        { id: 0, label: 'Доноры', to: '/donors' },
        { id: 1, label: 'Фотографии', to: '/galleria' },
        { id: 2, label: 'Номенклатура', to: '/dictionaries/nomenclature' },
        { id: 3, label: 'Поставки', to: '/dictionaries/lots' },
        { id: 4, label: 'Склады', to: '/dictionaries/locations' },
        { id: 5, label: 'Товары', to: '/dictionaries/products' },
      ],
    },
    {
      id: 5,
      label: 'Сервис',
      icon: 'pi pi-cog',
      items: [
        { id: 0, label: 'Профиль', to: '/services/profile' },
        { id: 0, label: 'Экспорт', to: '/services/export' },
        { id: 0, label: 'Импорт', to: '/services/import' },
      ],
    },
    {
      id: 6,
      label: 'Отчеты',
      icon: 'pi pi-chart-line',
      items: [{ id: 0, label: 'Поставки', to: '/reports/lots' }],
    },
  ],
};

export const navigationModule = createModule(store, 'navigation', {
  state,
  mutations: {},
  actions: {},
});
