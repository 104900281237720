<template>
  <div class="content">
    <Menubar :model="items" class="main-menu">
      <template #end>
        <div class="p-d-flex p-jc-center">
          <div class="p-mr-2 p-d-flex p-dir-col p-jc-center">
            <InputSwitch
              v-model="darkToggle"
              @change="changeTheme"
              v-tooltip.bottom="'Тема'"
            />
          </div>
          <div>
            <Avatar
              icon="pi pi-user"
              class="profile-avatar p-mr-2 p-ripple"
              v-ripple
              shape="circle"
              @click="toggle"
              aria-haspopup="true"
              aria-controls="overlay_menu"
            />
          </div>
        </div>
        <Menu :model="profileMenu" :popup="true" id="overlay_menu" ref="menu" />
      </template>
    </Menubar>
    <div class="p-d-flex p-flex-row">
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref, onMounted } from 'vue';
import Menubar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import InputSwitch from 'primevue/inputswitch';
import Ripple from 'primevue/ripple';

import { navigationModule } from '@/store/modules/navigation';
import { authModule } from '@/store/modules/auth';
import { useToast } from 'primevue/usetoast';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Menubar,
    Avatar,
    Menu,
    InputSwitch,
  },
  directives: {
    ripple: Ripple,
  },
  setup() {
    const menu = ref();
    const darkToggle = ref(false);
    const items = computed(() => navigationModule.state.menuItems);

    const router = useRouter();
    const toast = useToast();

    onMounted(() => {
      const theme = localStorage.getItem('theme') || 'vela-orange';
      darkToggle.value = theme !== 'vela-orange';
    });

    const logoutHandler = () => {
      try {
        authModule.actions.logout();
        router.push('/login');
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'Ошибка 500',
          detail: 'Ошибка сервера',
          life: 3000,
        });
      }
    };

    const profileMenu = ref([
      { id: 0, label: 'Профиль', to: '/services/profile' },
      { id: 1, separator: true },
      {
        id: 2,
        label: 'Выход',
        command: logoutHandler,
        icon: 'pi pi-fw pi-power-off',
      },
    ]);

    const toggle = event => {
      menu.value.toggle(event);
    };

    const changeTheme = () => {
      const dark = 'vela-orange';
      const light = 'saga-orange';
      let currentTheme = dark;
      let replaceTheme = light;

      if (!darkToggle.value) {
        currentTheme = light;
        replaceTheme = dark;
      }

      const themeElement = document.getElementById('theme-link');
      themeElement.setAttribute(
        'href',
        themeElement.getAttribute('href').replace(currentTheme, replaceTheme)
      );
      localStorage.setItem('theme', replaceTheme);
    };

    return {
      items,
      profileMenu,
      menu,
      darkToggle,
      toggle,
      logoutHandler,
      changeTheme,
    };
  },
});
</script>

<style scoped lang="scss">
.content {
}
:deep(.p-menubar).main-menu {
  .p-submenu-list {
    z-index: 10;
  }
}

:deep(.p-avatar).profile-avatar {
  cursor: pointer;
}
</style>
