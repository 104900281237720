import { IsNotEmpty, Length } from 'class-validator';

import { Entity } from './Entity';
import { VALID_ERRORS } from '@/constants/errors';

interface NomenclatureInterface {
  id?: number;
  name?: string;
  order?: number;
  parentId?: number;
}

export class Nomenclature
  extends Entity<Nomenclature>
  implements NomenclatureInterface {
  id?: number = 0;
  @IsNotEmpty({ message: VALID_ERRORS.required })
  @Length(3, 100, { message: VALID_ERRORS.length(3, 100) })
  name?: string = '';
  order?: number = 0;
  parentId?: number = 0;

  constructor(data: NomenclatureInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: NomenclatureInterface) {
    this.id = data.id || undefined;
    this.name = data.name || undefined;
    this.order = data.order || undefined;
    this.parentId = data.parentId || undefined;
  }
}
