import { createModule } from 'vuexok';
import axios from '@/axios-zaconte';

import store from '@/store';
import User from '@/classes/models/User';
import CustomError from '@/classes/models/CustomError';

export const authModule = createModule(store, 'auth', {
  state: {
    userId: '',
    token: '',
    expiresIn: '',
  },
  mutations: {
    updateAuthSession(state, payload: { userId: string; token: string }) {
      state.userId = payload.userId;
      state.token = payload.token;
      axios.defaults.headers.common['Authorization'] = payload.token;
    },
  },
  actions: {
    async logout() {
      await axios.get('user/logout');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      authModule.mutations.updateAuthSession({ userId: '', token: '' });
    },

    async login(state, body: { email: string; password: string }) {
      try {
        const respose = await axios.post('user/auth', body);
        axios.defaults.headers.common['Authorization'] = respose.data.token;

        authModule.mutations.updateAuthSession({
          userId: respose.data.id,
          token: respose.data.token,
        });
      } catch (error) {
        throw new CustomError(error.response);
      }
    },

    async register(state, user: User) {
      try {
        await axios.post('user', user);
      } catch (error) {
        console.dir(error);
        throw new CustomError(error.response);
      }
    },

    async resetRequest(
      state,
      payload: { email: string; guid: string; token: string }
    ) {
      try {
        await axios.post('user/pwd/reset/send', {
          email: payload.email,
          guid: payload.guid,
          token: payload.token,
        });
      } catch (error) {
        throw new CustomError(error.response);
      }
    },

    async reset(
      state,
      payload: { token: string; password: string; password2: string }
    ) {
      try {
        await axios.post('user/pwd/reset', payload);
      } catch (error) {
        throw new CustomError(error.response);
      }
    },

    async verify(state, payload: { token: string }) {
      try {
        await axios.get(`user/verify/${payload.token}`);
      } catch (error) {
        throw new CustomError(error.response);
      }
    },
  },
  getters: {
    loginData(state): { userId: string; token: string } {
      return {
        userId: state.userId,
        token: state.token,
      };
    },
  },
});
