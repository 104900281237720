const locale = {
  startsWith: 'Начинается с',
  contains: 'Содержит',
  notContains: 'Не содержит',
  endsWith: 'Заканчивается на',
  equals: 'Равно',
  notEquals: 'Не равно',
  noFilter: 'Без фильтра',
  lt: 'Меньше чем',
  lte: 'Меньше чем или равно',
  gt: 'Больше чем',
  gte: 'Больше чем или равно',
  dateIs: 'Дата',
  dateIsNot: 'Дата не',
  dateBefore: 'Дата до',
  dateAfter: 'Дата после',
  clear: 'Очистить',
  apply: 'Применить',
  matchAll: 'Соответствует всем',
  matchAny: 'Соответствует некоторым',
  addRule: 'Добавить правило',
  removeRule: 'Удалить правило',
  accept: 'Да',
  reject: 'Нет',
  choose: 'Выбрать',
  upload: 'Загрузить',
  cancel: 'Отмена',
  dayNames: [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Понедельник',
  ],
  dayNamesShort: ['Вск', 'Пнд', 'Втр', 'Срд', 'Чтв', 'Птн', 'Пнд'],
  dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Пн'],
  monthNames: [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
  monthNamesShort: [
    'Янв',
    'Фвр',
    'Мрт',
    'Апр',
    'Май',
    'Июн',
    'Июл',
    'Авг',
    'Сен',
    'Окт',
    'Ноя',
    'Дек',
  ],
  today: 'Сегодня',
  weekHeader: 'Нд',
  firstDayOfWeek: 0,
  dateFormat: 'dd/mm/yy',
  weak: 'Слабый',
  medium: 'Средний',
  strong: 'Сильный',
  passwordPrompt: 'Введите пароль',
  emptyFilterMessage: 'Результатов не найдено',
  emptyMessage: 'Нет доступных позиций',
};

export default locale;
