<template>
  <div class="layout-root">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DocumentLayout'
});
</script>

<style scoped>
.layout-root {
}
</style>
