import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import axios from './axios-zaconte';

import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import locale from './constants/locale.js';

import App from './App.vue';

import Tooltip from 'primevue/tooltip';

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
      router.push('/login');
    }
    return Promise.reject(error);
  }
);

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

const app = createApp(App);

app
  .directive('tooltip', Tooltip)
  .use(store)
  .use(router)
  .use(PrimeVue, { locale, ripple: true })
  .use(ToastService)
  .use(ConfirmationService)
  .mount('#app');
