export const VALID_ERRORS = {
  required: 'Объзательно к заполнению',
  isString: 'Разрешен только текст',
  isEmail: 'Недопустимый формат email',
  acceptTerms: 'Необходимо принять условия',
  passwordMatches: 'Пароли должны совпадать',
  emptyLines: 'Позиции документа должны быть заполнены',
  length: (min: number, max: number) =>
    `Не менее ${min}, но не более ${max} символов`,
  maxLength: (max: number) => `Не более ${max} символов`,
};

export const PART_ERRORS = {
  noDonor: 'Донор не выбран',
};

export const MESSAGES = {
  generalFormErrors: 'При проверке формы обнаружены ошибки!',
};

export const SERVER_VALID_ERRORS: { [key: string]: string } = {
  params: 'Некорретно заполнена форма',
  Email: 'Некорретный Email',
  CaptchaToken: 'Код с картинки введен неверно или устарел',
};

export const SERVER_ERRORS: { [key: string]: string } = {
  1: 'Некорректный запрос (код 1)',
  2: 'Пользователь не авторизован (код 2)',
  3: 'Ошибка сервера (код 3)',
  5: 'Действие запрещено (код 5)',
  10: 'Истек срок действия токена (код 10)',
  11: 'Код с картинки не верен или истек срок его действия (код 11)',
  12: 'Код с картинки не верен или истек срок его действия (код 12)',
  50: 'Переданные данные некорретны (код 50)',
  75: 'Формат изображения неизвестен (код 75)',
  76: 'Проблема с изображениями (код 76)',
  77: 'Изображение не найдено (код 77)',
  101: 'Пользователь с таким email уже зарегистрирован (код 101)',
  102: 'Пользователь не найден (код 102)',
  105: 'Неверный email или пароль! (код 105)',
  201: 'Рабочее пространство не найдено (код 201)',
  251: 'Донор не найден (код 251)',
  252: 'Поставка для донора указана неверно (код 252)',
  270: 'Склад не найден (код 270)',
  280: 'Номенклатура не найдена (код 280)',
  281: 'Номенклатура уже используется и не может быть удалена (код 281)',
  290: 'Поставка не найдена (код 290)',
  310: 'Запчасть не найден (код 310)',
  311: 'Склад для запчасти указана неверно (код 311)',
  312: 'Номенклатура (наименование) для запчасти указана неверно (код 312)',
  313: 'Поставка для запчасти указана неверно (код 313)',
  999: 'Неизвестная ошибка на сервере (код отсутвует)',
};

export const SERVER_STATUS: { [key: string]: string } = {
  404: 'Данные не найдены',
  500: 'Ошибка сервера',
};
