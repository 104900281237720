<template>
  <div class="p-d-flex p-flex-column p-ai-center p-jc-center content">
    <router-view />
  </div>
  <div class="version-container">v. {{ ver }}</div>
  <div class="terms-container"><router-link to="/mobile-policy">
    Условия использования
  </router-link></div>
</template>

<script>
import { defineComponent } from 'vue';
import 'primeflex/primeflex.css';

export default defineComponent({
  name: 'EmptyLayout',
  data: () => ({
    ver: process.env.VUE_APP_VERSION,
  }),
});
</script>

<style scoped>
a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.content {
  height: 100vh;
}
.version-container {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0.5rem;
}
.terms-container {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 0.5rem;
}
</style>
