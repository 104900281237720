
import { ref, computed, onMounted, defineComponent } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import Sidebar from 'primevue/sidebar';

import { authModule } from '@/store/modules/auth';
import { localsModule } from '@/store/modules/locals';
import { dictionariesModule } from '@/store/modules/dictionaries';

import MainLayout from './layouts/MainLayout.vue';
import EmptyLayout from './layouts/EmptyLayout.vue';
import DocumentLayout from './layouts/DocumentLayout.vue';

export default defineComponent({
  components: {
    'main-layout': MainLayout,
    'empty-layout': EmptyLayout,
    'doc-layout': DocumentLayout,
    Button,
    ConfirmDialog,
    Toast,
    Sidebar,
  },

  setup() {
    const route = useRoute();
    const termsBar = ref(false);

    const layout = computed(() => {
      return (route.meta.layout || 'empty') + '-layout';
    });

    function clearStorage() {
      localStorage.removeItem('token');
      localStorage.removeItem('expiryDate');
      localStorage.removeItem('userId');
    }

    onMounted(async () => {
      document.title = 'База данных запчастей';

      const theme = localStorage.getItem('theme') || 'vela-orange';
      const themeElement = document.getElementById('theme-link');
      if (themeElement) {
        themeElement.setAttribute(
          'href',
          // eslint-disable-next-line
          themeElement.getAttribute('href')!.replace('vela-orange', theme)
        );
      }

      const showTerms = !!localStorage.getItem('termsAgree');
      termsBar.value = !showTerms;

      const token = localStorage.getItem('token');
      if (!token) {
        clearStorage();
        return;
      }

      const userId = localStorage.getItem('userId');

      if (token && userId) {
        authModule.mutations.updateAuthSession({
          token: token,
          userId: userId,
        });

        localsModule.actions.loadLocalNomenclatures();
        await dictionariesModule.actions.loadNomenclatures();
      }
    });

    function agreeTerms() {
      localStorage.setItem('termsAgree', '1');
      termsBar.value = false;
    }

    return {
      layout,
      termsBar,
      agreeTerms,
    };
  },
});
