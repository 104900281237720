import { createModule } from 'vuexok';

import axios from '@/axios-zaconte';
import store from '@/store';

import { Nomenclature } from '@/classes/models/Nomenclature';
import { NomenclatureApi } from '@/classes/api/NomenclatureApi';
import { Lot } from '@/classes/models/Lot';
import { LotApi } from '@/classes/api/LotApi';

interface State {
  nomenclature: Nomenclature[];
  models: string[];
  marks: string[];
  lots: Lot[];
}

const state: State = {
  nomenclature: [],
  models: [],
  marks: [],
  lots: [],
};

const nomenclatureApi = new NomenclatureApi();
const lotApi = new LotApi();

export const dictionariesModule = createModule(store, 'dictionaries', {
  state,
  mutations: {
    setNomenclature(state, payload: Nomenclature[]) {
      state.nomenclature = payload;
    },

    setModels(state, payload: string[]) {
      state.models = payload;
    },

    setMarks(state, payload: string[]) {
      state.marks = payload;
    },

    setLots(state, payload: Lot[]) {
      state.lots = payload;
    },
  },
  actions: {
    async loadNomenclatures(): Promise<void> {
      try {
        const nomenclature: Nomenclature[] = await nomenclatureApi.fetchAll();
        dictionariesModule.mutations.setNomenclature(nomenclature);
      } catch (error) {
        console.error(error);
      }
    },

    async loadMarks(): Promise<void> {
      try {
        const marks = (
          await axios.get('directory/main', { params: { Full: false } })
        ).data as string[];
        dictionariesModule.mutations.setMarks(marks);
      } catch (error) {
        console.error(error);
      }
    },

    async loadModels(ctx, payload: { Marka: string }): Promise<void> {
      try {
        const models = (await axios.get('directory/main', { params: payload }))
          .data as string[];
        dictionariesModule.mutations.setModels(models);
      } catch (error) {
        console.error(error);
      }
    },

    async loadLots(): Promise<void> {
      try {
        const lots: Lot[] = await lotApi.fetchAll();
        dictionariesModule.mutations.setLots(lots);
      } catch (error) {
        console.error(error);
      }
    },
  },
});
