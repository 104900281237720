import { Api } from './Api';
import { Nomenclature } from '../models/Nomenclature';
import CustomError from '../models/CustomError';

export class NomenclatureApi extends Api {
  async fetchAll(): Promise<Nomenclature[]> {
    let nomenclature: Nomenclature[] = [];
    try {
      const result = await this.fetchAllProtected<Nomenclature[]>(
        'nomenclature'
      );
      if (result && result.length > 0) {
        nomenclature = Nomenclature.createFromArray(Nomenclature, result);
      }
      return [...nomenclature];
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetchById(id: number): Promise<Nomenclature> {
    try {
      const result = await this.fetchByIdProtected<Nomenclature>(
        'nomenclature',
        id
      );
      if (result) {
        return Nomenclature.createFromObject(Nomenclature, result);
      }
      return new Nomenclature({});
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async send(data: Nomenclature): Promise<Nomenclature> {
    try {
      const result = await this.sendProtected<Nomenclature>(
        'nomenclature',
        data
      );
      return Nomenclature.createFromObject(Nomenclature, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async update(data: Nomenclature): Promise<Nomenclature> {
    try {
      const result = await this.updateProtected('nomenclature', data);
      return Nomenclature.createFromObject(Nomenclature, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async remove(id: number) {
    try {
      await this.removeProtected('nomenclature', id);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}
