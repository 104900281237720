import { SERVER_ERRORS } from '@/constants/errors';

interface ResponseError {
  data: {
    Details?: string[];
    InternalCode: number;
    Message: string;
    ValidationErrors?: { Field: string; Error: string }[];
  };
  status: number;
}

class CustomError extends Error {
  private _errors: string[] = [];

  get errors() {
    return [...this._errors];
  }

  constructor(response: ResponseError) {
    super();
    switch (response?.status) {
      case 400:
        if (response.data.InternalCode === 50) {
          this.message = 'Ошибка валидации';
          if (response.data.ValidationErrors && response.data.ValidationErrors.length) {
            response.data.ValidationErrors.forEach((validError) => {
              this._errors.push(validError.Error);
            });
          } else {
            this._errors.push(SERVER_ERRORS[response.data.InternalCode.toString()]);
          }
        } else {
          this.message = 'Ошибка валидации';
          this._errors.push(SERVER_ERRORS[response.data.InternalCode.toString()]);
        }
        break;
      case 401:
        this.message = 'Вход не выполнен';
        this._errors.push(SERVER_ERRORS[response.data.InternalCode.toString()]);
        break;
      case 403:
        this.message = 'Ошибка авторизации';
        this._errors.push(SERVER_ERRORS[response.data.InternalCode.toString()]);
        break;
      case 500:
        this.message = 'Ошибка сервера';
        this._errors.push('Непредвиденная ошибка (Серверная 500). Обратитесь к администратору.');
        break;
      case 502:
        this.message = 'Ошибка сервера';
        this._errors.push('Техническая ошибка (Серверная 502). Обратитесь к администратору.');
        break;
      case 503:
        this.message = 'Ошибка сервера';
        this._errors.push('Сервер временно недоступен (Серверная 503). Повторите позднее.');
        break;
      case 504:
        this.message = 'Ошибка сервера';
        this._errors.push('Превышен интервал ожидания (Серверная 504). Повторите позднее.');
        break;
      case 505:
        this.message = 'Ошибка сервера';
        this._errors.push('Браузер неподдерживается или заражен (Серверная 505). Обратитесь к администратору.');
        break;
      default:
        this._errors = [];
    }
  }

  // eslint-disable-next-line
  show(severity: 'success' | 'warn' | 'error', life: number, toast: any) {
    this.errors.forEach((item) => {
      toast.add({
        severity,
        summary: this.message,
        detail: item,
        life,
      });
    });
  }
}

export default CustomError;
